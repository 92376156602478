/* eslint no-unused-vars: 0 */

import { navigate } from "gatsby";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import PropTypes from "prop-types";
import React from "react";
import "antd/lib/form/style/index.css";
import "antd/lib/input/style/index.css";
import "antd/lib/button/style/index.css";
import { ThemeContext } from "../../layouts";
import * as axios from "axios";

const FormItem = Form.Item;
const { TextArea } = Input;

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { submitError: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.sendMessage(values);
      }
    });
  }

  sendMessage(values) {
    axios
      .post("/api/contact", {
        email: values.email,
        message: values.message,
        name: values.name
      })
      .then(() => {
        console.log("Form submission success");
        navigate("/success");
      })
      .catch(error => {
        // console.error("Form submission error:", error);
        this.handleNetworkError(error);
      });
  }

  handleNetworkError(e) {
    // console.log("submit Error", e);
    this.setState({
      submitError: true
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { submitError } = this.state;

    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <div className="form">
              {submitError && (
                <div className="errorBox">
                  Leider ist bei der Übertragung ein Fehler aufgetreten! Sie können uns jedoch
                  unter&nbsp;
                  <a href="mailto:contact@hardtsolutions.de">contact@hardtsolutions.de</a>{" "}
                  kontaktieren.
                </div>
              )}

              <Form
                name="contact"
                onSubmit={this.handleSubmit}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                colon={false}
              >
                <FormItem label="Name">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        whitespace: true
                      }
                    ]
                  })(<Input name="name" />)}
                </FormItem>
                <FormItem label="E-Mail Adresse">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Bitte geben Sie Ihre E-Mail Adresse an",
                        whitespace: true,
                        type: "email"
                      }
                    ]
                  })(<Input name="email" />)}
                </FormItem>
                <FormItem label="Nachricht">
                  {getFieldDecorator("message", {
                    rules: [
                      {
                        required: true,
                        message: "Bitte geben Sie Ihre Nachricht ein",
                        whitespace: true
                      }
                    ]
                  })(<TextArea name="message" autosize={{ minRows: 4, maxRows: 10 }} />)}
                </FormItem>
                <FormItem>
                  <Button type="primary" htmlType="submit">
                    Senden
                  </Button>
                </FormItem>
              </Form>

              {/* language=SCSS */}
              <style jsx>{`
                .form {
                  background: transparent;
                }
                .form :global(.ant-row.ant-form-item) {
                  margin: 0 0 1em;
                }
                .form :global(.ant-row.ant-form-item:last-child) {
                  margin-top: 1em;
                }
                .form :global(.ant-form-item-control) {
                  line-height: 1em;
                }
                .form :global(.ant-form-item-label) {
                  line-height: 1em;
                  margin-bottom: 0.5em;
                }
                .form :global(.ant-form-item) {
                  margin: 0;
                }
                .form :global(.ant-input) {
                  appearance: none;
                  height: auto;
                  font-size: 1.2em;
                  padding: 0.5em 0.6em;
                }
                .form :global(.ant-btn-primary) {
                  height: auto;
                  font-size: 1.2em;
                  padding: 0.5em 3em;
                  background: ${theme.color.brand.primary};
                  border: 1px solid ${theme.color.brand.primary};
                }
                .form :global(.ant-btn-primary:hover) {
                  background-color: #56a68c;
                }

                .form :global(.ant-form-explain) {
                  margin-top: 0.2em;
                }

                .errorBox {
                  margin-bottom: 10px;
                  padding: 10px;
                  background-color: ${theme.color.special.attention};
                  color: ${theme.color.neutral.white};

                  a {
                    color: ${theme.color.neutral.white};

                    &:hover {
                      color: ${theme.color.neutral.gray.b};
                    }
                  }
                }

                @from-width desktop {
                  .form :global(input) {
                    max-width: 50%;
                  }
                }
              `}</style>
            </div>
          )}
        </ThemeContext.Consumer>
      </React.Fragment>
    );
  }
}

Contact.propTypes = {
  form: PropTypes.object
};

const ContactForm = Form.create({})(Contact);

export default ContactForm;
